import React from 'react'

const index = () => {
  return (
    <div>
      home
      list item
      lorem3000
    </div>
  )
}

export default index
