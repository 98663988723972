import './App.css';
/** @jsxImportSource @emotion/react */
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Detail from './pages/detail';
import Home from './pages/home/index';
import AddEdit from './pages/add-edit';

import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const App = () => {
  const [init, setInit] = useState(false);
  
  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container) => {
    console.log(container);
  }

  const options = useMemo(
    () => ({
      "background": {
        "color": {
          "value": "#ffffff"
        },
        "image": "url('https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg9weOydEU-dMjSpaaQ4BEyESfpq1hoayEGt3ZwjpCmBb85Bt2PYMt5h1qZ31jFsjioqEWYv1JE6VROiGBoxdTr_tYwpsDwYqL5dKDrsFn1VdJxdR-Did4fd9pPKqTuVE0eGFgYrT3Vc0Cejnn6XvOhj7qGKkTEer2B6AZbC-GW3Vl7FtanKM9X0lVD0Y8q/s1170/web-ban-hang.jpg')",
        "position": "50% 50%",
        "repeat": "no-repeat",
        "size": "cover",
        "opacity": 1
      },
      "backgroundMask": {
        "composite": "destination-out",
        "cover": {
          "color": {
            "value": {
              "r": 255,
              "g": 255,
              "b": 255
            }
          },
          "opacity": 1
        },
        "enable": true
      },
      "clear": true,
      "defaultThemes": {},
      "delay": 0,
      "fullScreen": {
        "enable": true,
        "zIndex": 0
      },
      "detectRetina": true,
      "duration": 0,
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "push",
          },
          onHover: {
            enable: true,
            mode: "repulse",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
        },
      },
      particles: {
        "bounce": {
          "horizontal": {
            "value": 1
          },
          "vertical": {
            "value": 1
          }
        },
        "collisions": {
          "absorb": {
            "speed": 2
          },
          "bounce": {
            "horizontal": {
              "value": 1
            },
            "vertical": {
              "value": 1
            }
          },
          "enable": false,
          "maxSpeed": 50,
          "mode": "bounce",
          "overlap": {
            "enable": true,
            "retries": 0
          }
        },
        "color": {
          "value": "#ffffff",
          "animation": {
            "h": {
              "count": 0,
              "enable": false,
              "speed": 1,
              "decay": 0,
              "delay": 0,
              "sync": true,
              "offset": 0
            },
            "s": {
              "count": 0,
              "enable": false,
              "speed": 1,
              "decay": 0,
              "delay": 0,
              "sync": true,
              "offset": 0
            },
            "l": {
              "count": 0,
              "enable": false,
              "speed": 1,
              "decay": 0,
              "delay": 0,
              "sync": true,
              "offset": 0
            }
          }
        },
        "effect": {
          "close": true,
          "fill": true,
          "options": {},
          "type": {}
        },
        "groups": {},
        "move": {
          "angle": {
            "offset": 0,
            "value": 90
          },
          "attract": {
            "distance": 200,
            "enable": false,
            "rotate": {
              "x": 3000,
              "y": 3000
            }
          },
          "center": {
            "x": 50,
            "y": 50,
            "mode": "percent",
            "radius": 0
          },
          "decay": 0,
          "distance": {},
          "direction": "none",
          "drift": 0,
          "enable": true,
          "gravity": {
            "acceleration": 9.81,
            "enable": false,
            "inverse": false,
            "maxSpeed": 50
          },
          "path": {
            "clamp": true,
            "delay": {
              "value": 0
            },
            "enable": false,
            "options": {}
          },
          "outModes": {
            "default": "out"
          },
          "random": false,
          "size": false,
          "speed": 2,
          "spin": {
            "acceleration": 0,
            "enable": false
          },
          "straight": false,
          "trail": {
            "enable": false,
            "length": 10,
            "fill": {}
          },
          "vibrate": false,
          "warp": false
        },
        "number": {
          "density": {
            "enable": true,
            "width": 1920,
            "height": 1080
          },
          "limit": {
            "mode": "delete",
            "value": 0
          },
          "value": 80
        },
        "opacity": {
          "value": 1,
          "animation": {
            "count": 0,
            "enable": false,
            "speed": 2,
            "decay": 0,
            "delay": 0,
            "sync": false,
            "mode": "auto",
            "startValue": "random",
            "destroy": "none"
          }
        },
        "reduceDuplicates": false,
        "shadow": {
          "blur": 0,
          "color": {
            "value": "#000"
          },
          "enable": false,
          "offset": {
            "x": 0,
            "y": 0
          }
        },
        "shape": {
          "close": true,
          "fill": true,
          "options": {},
          "type": "circle"
        },
        "size": {
          "value": {
            "min": 1,
            "max": 30
          },
          "animation": {
            "count": 0,
            "enable": false,
            "speed": 5,
            "decay": 0,
            "delay": 0,
            "sync": false,
            "mode": "auto",
            "startValue": "random",
            "destroy": "none"
          }
        },
        "stroke": {
          "width": 0
        },
        "zIndex": {
          "value": 0,
          "opacityRate": 1,
          "sizeRate": 1,
          "velocityRate": 1
        },
        "destroy": {
          "bounds": {},
          "mode": "none",
          "split": {
            "count": 1,
            "factor": {
              "value": 3
            },
            "rate": {
              "value": {
                "min": 4,
                "max": 9
              }
            },
            "sizeOffset": true
          }
        },
        "roll": {
          "darken": {
            "enable": false,
            "value": 0
          },
          "enable": false,
          "enlighten": {
            "enable": false,
            "value": 0
          },
          "mode": "vertical",
          "speed": 25
        },
        "tilt": {
          "value": 0,
          "animation": {
            "enable": false,
            "speed": 0,
            "decay": 0,
            "sync": false
          },
          "direction": "clockwise",
          "enable": false
        },
        "twinkle": {
          "lines": {
            "enable": false,
            "frequency": 0.05,
            "opacity": 1
          },
          "particles": {
            "enable": false,
            "frequency": 0.05,
            "opacity": 1
          }
        },
        "wobble": {
          "distance": 5,
          "enable": false,
          "speed": {
            "angle": 50,
            "move": 10
          }
        },
        "life": {
          "count": 0,
          "delay": {
            "value": 0,
            "sync": false
          },
          "duration": {
            "value": 0,
            "sync": false
          }
        },
        "rotate": {
          "value": 0,
          "animation": {
            "enable": false,
            "speed": 0,
            "decay": 0,
            "sync": false
          },
          "direction": "clockwise",
          "path": false
        },
        "orbit": {
          "animation": {
            "count": 0,
            "enable": false,
            "speed": 1,
            "decay": 0,
            "delay": 0,
            "sync": false
          },
          "enable": false,
          "opacity": 1,
          "rotation": {
            "value": 45
          },
          "width": 1
        },
        "links": {
          "blink": false,
          "color": {
            "value": "#ffffff"
          },
          "consent": false,
          "distance": 150,
          "enable": true,
          "frequency": 1,
          "opacity": 1,
          "shadow": {
            "blur": 5,
            "color": {
              "value": "#000"
            },
            "enable": false
          },
          "triangles": {
            "enable": false,
            "frequency": 1
          },
          "width": 1,
          "warp": false
        },
        "repulse": {
          "value": 0,
          "enabled": false,
          "distance": 1,
          "duration": 1,
          "factor": 1,
          "speed": 1
        }
      },
      "pauseOnBlur": true,
      "pauseOnOutsideViewport": true,
      // "responsive": {},
      "smooth": false,
      "style": {},
      // "themes": {},
      "zLayers": 100,
      "name": "Background Mask",
      "motion": {
        "disable": false,
        "reduce": {
          "factor": 4,
          "value": true
        }
      }
    }),
    [],
  );

  return (
    <div className="App">
      {init && <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={options}
      />}
      <div className="App-commingsoon">
      <svg width="800" height="200" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="100%" fill="white"/>
        <g fontSize="50" fill="#32CD32" fontWeight="bold" textAnchor="middle" dominantBaseline="middle" fontFamily="Arial">
          <text x="100" y="100" opacity="0">
            U
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="0s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.5s" repeatCount="indefinite" />
          </text>
          <text x="150" y="100" opacity="0">
            n
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="0.2s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.7s" repeatCount="indefinite" />
          </text>
          <text x="200" y="100" opacity="0">
            d
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="0.4s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="0.9s" repeatCount="indefinite" />
          </text>
          <text x="250" y="100" opacity="0">
            e
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="0.6s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="1.1s" repeatCount="indefinite" />
          </text>
          <text x="300" y="100" opacity="0">
            r
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="0.8s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="1.3s" repeatCount="indefinite" />
          </text>
          <text x="370" y="100" opacity="0">
            c
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="1s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="1.5s" repeatCount="indefinite" />
          </text>
          <text x="420" y="100" opacity="0">
            o
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="1.2s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="1.7s" repeatCount="indefinite" />
          </text>
          <text x="470" y="100" opacity="0">
            n
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="1.4s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="1.9s" repeatCount="indefinite" />
          </text>
          <text x="520" y="100" opacity="0">
            s
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="1.6s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="2.1s" repeatCount="indefinite" />
          </text>
          <text x="570" y="100" opacity="0">
            t
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="1.8s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="2.3s" repeatCount="indefinite" />
          </text>
          <text x="620" y="100" opacity="0">
            r
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="2s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="2.5s" repeatCount="indefinite" />
          </text>
          <text x="670" y="100" opacity="0">
            u
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="2.2s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="2.7s" repeatCount="indefinite" />
          </text>
          <text x="720" y="100" opacity="0">
            c
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="2.4s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="2.9s" repeatCount="indefinite" />
          </text>
          <text x="770" y="100" opacity="0">
            t
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="2.6s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="3.1s" repeatCount="indefinite" />
          </text>
          <text x="820" y="100" opacity="0">
            i
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="2.8s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="3.3s" repeatCount="indefinite" />
          </text>
          <text x="870" y="100" opacity="0">
            o
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="3s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="3.5s" repeatCount="indefinite" />
          </text>
          <text x="920" y="100" opacity="0">
            n
            <animate attributeName="opacity" from="0" to="1" dur="1s" begin="3.2s" repeatCount="indefinite" />
            <animate attributeName="opacity" from="1" to="0" dur="1s" begin="3.7s" repeatCount="indefinite" />
          </text>
        </g>
      </svg>

      </div>
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/add-edit/:id" element={<AddEdit/>} />
            <Route path="/:id" element={<Detail/>} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
